import LoaderComponent from '../FullScreenLoader/components/LoaderComponent';
import { useStyles } from './styles';

const RenderingLoader = () => {
	const { classes } = useStyles();

	return (
		<>
			<LoaderComponent />
			<p className={classes.renderingText}>
				Your video is rendering...
				<br />
				<span className={classes.doNotCloseText}>Please don&apos;t close this tab</span>
			</p>
		</>
	);
};

export default RenderingLoader;
