import React from 'react';
import { Title } from '@mantine/core';
import Card from '../../components/Card/Card';
import BaseScreenWithBackground from '../../components/BaseScreenWithBackground/BaseScreenWithBackground';
import TitleAndSubtitle from '../../components/TitleAndSubtitle/TitleAndSubtitle';
import { useStyles } from './styles';

const NoMatch = () => {
	const { classes } = useStyles();

	return (
		<BaseScreenWithBackground>
			<Card>
				<Title
					className={classes.title}
					order={2}
				>
					{'Nothing to \nsee here.'}
				</Title>
				<TitleAndSubtitle
					title="404"
					subtitle="Sorry, Looks like the page you were looking for is invalid. Try going back to the previous page."
				/>
			</Card>
		</BaseScreenWithBackground>
	);
};

export default NoMatch;
