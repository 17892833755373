import { LoadingOverlay } from '@mantine/core';
import { designSystem } from '../../designSystem';
import LoaderComponent from './components/LoaderComponent';
import RenderingLoader from '../RenderingLoader/RenderingLoader';

interface FullScreenLoaderProps {
		visible: boolean;
		rendering?: boolean;
}

const FullScreenLoader = ( { visible, rendering }: FullScreenLoaderProps ) => (
	<LoadingOverlay
		visible={visible}
		overlayColor={designSystem.palette.black}
		overlayOpacity={1}
		loader={rendering ? <RenderingLoader /> : <LoaderComponent />}
	/>
);

export default FullScreenLoader;
