import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { LOGIN_AS_SENDER_PATH } from '../routes';

interface RequireAuthProps {
children: JSX.Element
}

const RequireAuth = ( { children }: RequireAuthProps ) => {
	const { authStore } = useRootStore();

	return authStore.isAuthenticated
		? children
		: <Navigate to={LOGIN_AS_SENDER_PATH} />;
};

export default observer( RequireAuth );
