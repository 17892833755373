import { makeObservable, observable } from 'mobx';
import Asset, { AssetConstructor, AssetJSON } from './Asset';
import AssetCategory from './AssetCategory';

interface StickerConstructor extends AssetConstructor {
	thumbnailSrc: string;
	categoryIds: number[];
}

export interface StickerTemplateJSON extends AssetJSON {
	thumbnail_src: string;
	sticker_category_ids: number[];
}

abstract class StickerTemplate extends Asset<['categories']> {
	thumbnailSrc: string;
	categoryIds: number[];

	constructor( { thumbnailSrc, categoryIds, ...assetFields }: StickerConstructor ) {
		super( assetFields );
		this.thumbnailSrc = thumbnailSrc;
		this.categoryIds = categoryIds;

		makeObservable( this, {
			thumbnailSrc: observable,
			categoryIds: observable,
		} );
	}

	get categories() {
		return super.getRelationship<AssetCategory[]>( 'categories' );
	}

	update( sticker: Omit<StickerConstructor, 'id'> ) {
		this.label = sticker.label;
		this.src = sticker.src;
		this.thumbnailSrc = sticker.thumbnailSrc;
		this.categoryIds = sticker.categoryIds;
	}

	toJSON() {
		return {
			id: this.id,
			label: this.label,
			src: this.src,
			thumbnail_src: this.thumbnailSrc,
			sticker_category_ids: this.categoryIds,
		};
	}

	protected static isValid( json: Partial<StickerTemplateJSON> ): json is StickerTemplateJSON {
		return json.id !== undefined
		&& json.label !== undefined
		&& json.src !== undefined
		&& json.thumbnail_src !== undefined
		&& json.sticker_category_ids !== undefined;
	}
}

export default StickerTemplate;
