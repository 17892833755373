import { createStyles } from '@mantine/core';
import gradient from '../../assets/backgrounds/gradient-1.png';

export const useStyles = createStyles( () => ( {
	container: {
		backgroundImage: `url(${gradient})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '1rem',
		gap: '1rem',
	},

	withHeader: {
		minHeight: 'calc(100vh - 3.125rem)',
	},

	withoutHeader: {
		minHeight: '100vh',
	},
} ) );
