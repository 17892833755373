import Branding, { BrandingJSON } from './Branding';

interface MerchantConstructor {
	id: number;
	name: string;
	branding: Branding | null;
}

export interface MerchantJSON {
	id: number;
	name: string;
	branding: BrandingJSON | null;
}

class Merchant {
	id: number;
	name: string;
	branding: Branding | null;

	constructor( {
		id, name, branding,
	}: MerchantConstructor,
	) {
		this.id = id;
		this.name = name;
		this.branding = branding;
	}

	toJSON(): MerchantJSON {
		return {
			id: this.id,
			name: this.name,
			branding: this.branding?.toJSON() || null,
		};
	}

	static fromJSON( json: Partial<MerchantJSON> ) {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new this( {
			id: json.id,
			name: json.name,
			branding: ( json.branding && Branding.fromJSON( json.branding ) ) || null,
		} );
	}

	get defaultCtaLabel() {
		return this.name.length < 26 ? `Shop ${this.name}` : 'Shop Now';
	}

	private static isValid( json: Partial<MerchantJSON> ): json is MerchantJSON {
		return json.id !== undefined
    && json.name !== undefined;
	}
}

export default Merchant;
