export const designSystem = {
	palette: {
		primary: {
			black: '#000000',
			violet: [ '#F8EBFF', '#E1A8FF', '#CA64FF', '#A633E2', '#7D10B6' ],
			blue: [ '#F1F1FF', '#B6B6FF', '#6969FF', '#0000FF', '#0101B8' ],
		},
		accent: {
			cyan: '#00F0FF',
			pink: '#FFD4D4',
			violet: '#CA64FF',
		},
		grey: [
			'#FAFAFA',
			'#EBECEC',
			'#B5B6B8',
			'#797C7E',
			'#2A2F32',
		],
		white: '#FFFFFF',
		black: '#000000',
		success: {
			default: '#1D7A18',
			light: '#EEFAEF',
		},
		error: {
			default: '#D00C01',
			light: '#FEF5F6',
		},
		warning: {
			default: '#B66C26',
			light: '#FDF5E6',
		},
		info: {
			default: '#364FC7',
			light: '#EDF2FF',
		},
		gradients: {
			primary: 'linear-gradient(251.66deg, #00F0FF -11.97%, #FFD5D5 50.14%, #CA64FF 103.81%);',
			merchant: 'linear-gradient(251.66deg, #23DFEB -11.97%, #D1FFF4 50.14%, #9747FF 103.81%);',
		},
	},
	fonts: {
		headers: {
			header1: {
				fontFamily: 'League Spartan',
				fontSize: '2.375rem',
				lineHeight: '2.25rem',
				fontWeight: 500,
			},
			header2: {
				fontFamily: 'League Spartan',
				fontSize: '1.75rem',
				lineHeight: '2.25rem',
				fontWeight: 500,
			},
			header3: {
				fontFamily: 'League Spartan',
				fontSize: '1.375rem',
				lineHeight: '2.25rem',
				fontWeight: 500,
			},
		},
		other: {
			bodyRegular: {
				fontFamily: 'Satoshi',
				fontSize: '1rem',
				lineHeight: '1.5rem',
				fontWeight: 400,
			},
			bodyBold: {
				fontFamily: 'Satoshi',
				fontSize: '1rem',
				lineHeight: '1.5rem',
				fontWeight: 700,
			},
			captionRegular: {
				fontFamily: 'Satoshi',
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				fontWeight: 400,
			},
			captionBold: {
				fontFamily: 'Satoshi',
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				fontWeight: 700,
			},
			mediumLink: {
				fontFamily: 'Satoshi',
				fontSize: '0.875rem',
				lineHeight: '1.1875rem',
				fontWeight: 500,
			},
			smallLink: {
				fontFamily: 'Satoshi',
				fontSize: '0.75rem',
				lineHeight: '1rem',
				fontWeight: 500,
			},
			smallText: {
				fontFamily: 'Satoshi',
				fontSize: '1.125rem',
				lineHeight: '1.5rem',
				fontWeight: 400,
			},
			largeButton: {
				fontFamily: 'Satoshi',
				fontSize: '1.25rem',
				lineHeight: '1.6875rem',
				fontWeight: 700,
			},
			mediumButton: {
				fontFamily: 'Satoshi',
				fontSize: '1.125rem',
				lineHeight: '1.5rem',
				fontWeight: 700,
			},
			smallButton: {
				fontFamily: 'Satoshi',
				fontSize: '0.9375rem',
				lineHeight: '1.25rem',
				fontWeight: 700,
			},
		},
	},
};

export interface DesignSystemFont {
	fontFamily?: string
	fontSize?: string
	lineHeight?: string
	letterSpacing?: number
	fontWeight?: number
}
