import { createStyles } from '@mantine/core';

export const useStyles = createStyles( theme => ( {
	titleAndSubtitleContainer: {
		textAlign: 'center',
		maxWidth: '15.81rem',
	},

	title: {
		...theme.headings.sizes.h3,
		fontWeight: theme.headings.fontWeight,
		whiteSpace: 'pre-wrap',
		marginBottom: '0.5rem',
	},

	subtitle: {
		...theme.other.fonts.bodyRegular,
	},
} ) );
