const REFRESH_TOKEN_GAP_IN_MINUTES = 10;

export interface AccessTokenJSON {
    access_token: string,
    token_type: string,
    expires_in: number,
    refresh_token: string,
    created_at: number,
}

export default class AccessToken {
	token: string;
	refreshToken: string;
	tokenType: string;
	expiresIn: number;
	createdAt: number;

	constructor(
		token: string,
		refreshToken: string,
		tokenType: string,
		expiresIn: number,
		createdAt: number,
	) {
		this.token = token;
		this.refreshToken = refreshToken;
		this.tokenType = tokenType;
		this.expiresIn = expiresIn;
		this.createdAt = createdAt;
	}

	static fromJSON( json: Partial<AccessTokenJSON> ) {
		if ( !this.isValidJSON( json ) ) {
			throw new Error( 'invalid JSON' );
		}

		return new AccessToken(
			json.access_token,
			json.refresh_token,
			json.token_type,
			json.expires_in,
			json.created_at,
		);
	}

	toJSON() {
		return {
			access_token: this.token,
			refresh_token: this.refreshToken,
			token_type: this.tokenType,
			expires_in: this.expiresIn,
			created_at: this.createdAt,
		};
	}

	isValid() {
		return this.currentDateWithOffset.getTime() < this.expirationDate.getTime();
	}

	private get expirationDate() {
		return new Date( this.expirationDateInMilliseconds );
	}

	private get expirationDateInMilliseconds() {
		return ( this.createdAt + this.expiresIn ) * 1000;
	}

	private get currentDateWithOffset() {
		const now = new Date();
		now.setMinutes( now.getMinutes() + REFRESH_TOKEN_GAP_IN_MINUTES );
		return now;
	}

	private static isValidJSON( json: Partial<AccessTokenJSON> ): json is AccessTokenJSON {
		return json.access_token !== undefined
						&& json.token_type !== undefined
						&& json.expires_in !== undefined
						&& json.refresh_token !== undefined;
	}
}
