import { makeAutoObservable } from 'mobx';
import StorageService from '../services/StorageService';

export default class TokenStore {
	storageService: StorageService;
	accessToken: string | null;
	key: string;

	constructor( key: string, storageService: StorageService = StorageService.localStorage() ) {
		this.accessToken = null;
		this.key = key;
		this.storageService = storageService;
		makeAutoObservable( this );

		this.checkForTokenInStorage();
	}

	get isAuthenticated() {
		return !!this.accessToken;
	}

	get token() {
		return this.accessToken;
	}

	tokenIsValid() {
		return true;
	}

	setAccessToken( token: string | null ) {
		this.setTokenInStorage( token );
		this.accessToken = token;
	}

	clear() {
		this.setAccessToken( null );
	}

	private checkForTokenInStorage() {
		const token = this.storageService.getItem( this.key );
		if ( token ) this.accessToken = token;
	}

	private setTokenInStorage( token: string | null ) {
		if ( token ) {
			this.storageService.setItem( this.key, token, { expirationInDays: 1 } );
		} else {
			this.storageService.removeItem( this.key );
		}
	}
}
