import React from 'react';
import {
	ToastContainer as TostifyToastContainer, ToastContainerProps,
} from 'react-toastify';
import { useStyles } from './styles';

const ToastContainer = ( props: ToastContainerProps ) => {
	const { classes, cx } = useStyles();
	return (
		<TostifyToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			toastClassName={cx(
				classes.toastInfo,
				classes.toastSuccess,
				classes.toastWarning,
				classes.toastError )}
			{...props}
		/>
	);
};

export default ToastContainer;
