import { makeObservable, observable } from 'mobx';
import RootStore from '../stores/RootStore';
import StoreEntity from '../stores/StoreEntity';

export interface AssetConstructor {
	id: number;
	label: string;
	src: string;
}

export interface AssetJSON {
	id: number;
	label: string;
	src: string;
}

abstract class Asset<T extends unknown[]> extends StoreEntity<T> {
	id: number;
	label: string;
	src: string;

	constructor(
		{ id, label, src }: AssetConstructor,
		rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.label = label;
		this.src = src;

		makeObservable( this, {
			id: observable,
			label: observable,
			src: observable,
		} );
	}

	abstract toJSON(): AssetJSON;
}

export default Asset;
