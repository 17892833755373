import { makeObservable, observable } from 'mobx';
import StoreEntity from '../stores/StoreEntity';
import RootStore from '../stores/RootStore';
import { RelationshipConfig } from '../stores/relations/EntityRelationsFactory';
import Merchant, { MerchantJSON } from './Merchant';
import Slot, { SlotJSON } from './Slot';
import Branding, { BrandingJSON } from './Branding';

interface GiftCardGroupConstructor {
	id: number;
	slotId: number | null;
	cardCount: number;
	scannedCardCount: number;
	merchant: Merchant | null;
	branding: Branding | null;
	slotStatus?: Slot['status']
}

export interface GiftCardGroupJSON {
	id: number;
	slot_id: number | null;
	cards_count: number;
	scanned_card_count: number;
	merchant: MerchantJSON | null;
	branding: BrandingJSON | null;
	slot_status?: SlotJSON['status'];
}

export default class GiftCardGroup extends StoreEntity<['slot']> {
	readonly id: number;
	slotId: number | null;
	cardCount: number;
	scannedCardCount: number;
	merchant: Merchant | null;
	branding: Branding | null;
	slotStatus?: Slot['status'];

	constructor( {
		id,
		slotId,
		cardCount,
		scannedCardCount,
		branding,
		merchant,
		slotStatus,
	}: GiftCardGroupConstructor,
	rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.slotId = slotId;
		this.cardCount = cardCount;
		this.scannedCardCount = scannedCardCount;
		this.merchant = merchant;
		this.branding = branding;
		this.slotStatus = slotStatus;

		makeObservable( this, {
			id: observable,
			slotId: observable,
			cardCount: observable,
			scannedCardCount: observable,
			branding: observable,
			merchant: observable,
		} );
	}

	static relationships(): RelationshipConfig[] {
		return [
			{
				name: 'slot',
				lookupKey: 'slotId',
				store: 'slotStore',
				type: 'BELONGS_TO',
			},
		];
	}

	get wasCompleted() {
		return this.slot ? this.slot?.wasCompleted : this.slotStatus === 'completed';
	}

	get wasActivated() {
		return this.wasCompleted || ( this.slot ? this.slot?.wasActivated : this.slotStatus === 'activated' );
	}

	get senderEmail() {
		return this.slot?.sender ? this.slot?.sender.email : null;
	}

	get from() {
		return this.slot?.from || null;
	}

	get to() {
		return this.slot?.to || null;
	}

	get videoUrl() {
		return this.slot?.videoUrl;
	}

	get merchantName() {
		return this.merchant?.name;
	}

	get currentBranding() {
		return this.branding || this.merchant?.branding;
	}

	get defaultCtaLabel() {
		return this.merchant?.defaultCtaLabel;
	}

	get slot() {
		return super.getRelationship<Slot>( 'slot' );
	}

	get wasScanned() {
		return this.scannedCardCount > 0;
	}

	toJSON(): GiftCardGroupJSON {
		return {
			id: this.id,
			slot_id: this.slotId,
			cards_count: this.cardCount,
			scanned_card_count: this.scannedCardCount,
			merchant: this.merchant?.toJSON() || null,
			branding: this.branding?.toJSON() || null,
			slot_status: this.slotStatus,
		};
	}

	static fromJSON( json: Partial<GiftCardGroupJSON>, rootStore?: RootStore ): GiftCardGroup {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new GiftCardGroup( {
			id: json.id,
			slotId: json.slot_id,
			cardCount: json.cards_count,
			scannedCardCount: json.scanned_card_count,
			merchant: ( json.merchant && Merchant.fromJSON( json.merchant ) ) || null,
			branding: ( json.branding && Branding.fromJSON( json.branding ) ) || null,
			slotStatus: json.slot_status,
		}, rootStore );
	}

	update( giftCard: Omit<GiftCardGroupConstructor, 'id'> ) {
		this.slotId = giftCard.slotId;
		this.cardCount = giftCard.cardCount;
		this.scannedCardCount = giftCard.scannedCardCount;
		this.slotStatus = giftCard.slotStatus;
		this.merchant = giftCard.merchant;
	}

	private static isValid( json: Partial<GiftCardGroupJSON> ): json is GiftCardGroupJSON {
		return true;
	}
}
