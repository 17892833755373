/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { makeObservable, ObservableMap } from 'mobx';
import RootStore from './RootStore';

interface IEntityKlass<T> {
    fromJSON: ( attributes: any, rootStore?: RootStore ) => T
}

export interface IEntity {
    id: number,
    rootStore?: RootStore
}

export interface EntityStoreOptions {
		persists?: boolean
}

export default class EntityStore<T extends IEntity> {
	private entityMap = new Map() as ObservableMap;
	private EntityKlass: IEntityKlass<T>;
	private rootStore: RootStore;
	persists: boolean;

	constructor( EntityKlass: IEntityKlass<T>, rootStore: RootStore, options?: EntityStoreOptions ) {
		this.EntityKlass = EntityKlass;
		this.rootStore = rootStore;
		this.persists = options?.persists ?? true;

		makeObservable<EntityStore<T>, 'EntityKlass' | 'entityMap' | 'rootStore'>( this, {
			EntityKlass: false,
			rootStore: false,
			entityMap: true,
			all: true,
			where: true,
			add: true,
			delete: true,
			deleteAll: true,
			create: true,
			get: true,
			has: true,
			replace: true,
			clear: true,
			toJSON: true,
			hydrate: true,
		} );
	}

	all() {
		return Array.from(
			this.entityMap.values(),
		);
	}

	where( condition: ( entity: T ) => boolean ): T[] {
		return this.all().filter( condition );
	}

	add( entity: T ) {
		if ( this.has( entity.id ) ) {
			this.updateExistentWith( entity );
		} else {
			this.addToStore( entity );
		}

		return this.get( entity.id );
	}

	delete( id: number ) {
		this.entityMap.delete( id );
	}

	deleteAll( ids: number[] ) {
		ids.forEach( entity => this.delete( entity ) );
	}

	create( attributes: any ) {
		const entity = this.EntityKlass.fromJSON( attributes, this.rootStore );
		return this.add( entity );
	}

	get( id: number ) {
		return this.entityMap.get( id ) || null;
	}

	has( id: number ) {
		return this.entityMap.has( id );
	}

	replace( newEntities: T[] ) {
		const entries = newEntities.map( ( entity ) => {
			this.setEntityRootStore( entity );
			return [ entity.id, entity ];
		} );

		this.entityMap.replace( entries );
	}

	clear() {
		this.replace( [] );
	}

	toJSON() {
		return this.all().map( entity => entity.toJSON() );
	}

	hydrate( json: any[] ) {
		json.forEach( jsonEntity => this.create( jsonEntity ) );
	}

	private addToStore( entity: T ) {
		this.setEntityRootStore( entity );
		this.entityMap.set( entity.id, entity );
	}

	private updateExistentWith( entity: T ) {
		this.get( entity.id ).update( entity );
	}

	private setEntityRootStore( entity: T ) {
		if ( !( 'rootStore' in entity ) ) return;

		entity.rootStore = this.rootStore;
	}
}
