import ToastService from './ToastService';

class UIService {
	toastService: ToastService;

	constructor() {
		this.toastService = new ToastService();
	}
}

export default UIService;
