import React, { ReactNode, useEffect, useState } from 'react';
import RootStore from '../stores/RootStore';
import RootStorePersistor from '../stores/RootStorePersistor';

export const RootStoreContext = React.createContext( {} as RootStore );

export const RootStoreProvider = ( { children }: { children: ReactNode } ) => {
	const [ rootStore ] = useState( new RootStore() );

	return (
		<RootStoreContext.Provider value={rootStore}>
			{children}
		</RootStoreContext.Provider>
	);
};

export const useRootStore = () => React.useContext( RootStoreContext );

export const PersistGate = (
	{ children, fallback = null }: {children?: ReactNode, fallback?: ReactNode},
) => {
	const rootStore = useRootStore();
	const [ persistor ] = useState( new RootStorePersistor( rootStore ) );
	const [ isHydratingStore, setIsHydratingStore ] = useState( true );

	useEffect( () => {
		persistor.hydrate();
		setIsHydratingStore( false );
	}, [ persistor ] );

	return ( isHydratingStore ? fallback : children ) as JSX.Element;
};
