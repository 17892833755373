import { ReactNode } from 'react';
import SVG from 'react-inlinesvg';
import { useStyles } from './styles';
import SWAQIcon from '../../assets/icons/swaq-icon-alt.svg';

interface HeaderProps {
		rightSection?: ReactNode;
		withBorder?: boolean;
		HeaderLogo?: ReactNode;
		className?: string;
}

const Header = ( {
	rightSection, withBorder = true, HeaderLogo, className,
}: HeaderProps ) => {
	const { classes, cx } = useStyles();
	return (
		<header className={cx( classes.header, withBorder && classes.border, className )}>
			{HeaderLogo || <SVG src={SWAQIcon} uniquifyIDs />}
			{rightSection && (
				<span className={classes.rightSection}>
					{rightSection}
				</span>
			)}
		</header>
	);
};

export default Header;
