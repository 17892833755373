import React from 'react';
import { RootStoreProvider } from './RootStoreProvider';
import UIServiceProvider from './UIServiceProvider';

const AppProvider = ( { children }: { children: React.ReactNode } ) => (
	<RootStoreProvider>
		<UIServiceProvider>
			{children}
		</UIServiceProvider>
	</RootStoreProvider>
);

export default AppProvider;
