import { RelationshipConfig } from '../stores/relations/EntityRelationsFactory';
import StickerTemplate, { StickerTemplateJSON } from './StickerTemplate';

class StaticStickerTemplate extends StickerTemplate {
	static relationships(): RelationshipConfig[] {
		return [ {
			name: 'categories',
			type: 'HAS_MANY',
			store: 'staticStickerCategoryStore',
			lookupKey: 'categoryIds',
		} ];
	}

	static fromJSON( json: Partial<StickerTemplateJSON> ) {
		if ( !this.isValid( json ) ) { throw new Error( 'Invalid JSON' ); }
		return new this( {
			id: json.id,
			label: json.label,
			src: json.src,
			thumbnailSrc: json.thumbnail_src,
			categoryIds: json.sticker_category_ids,
		} );
	}
}

export default StaticStickerTemplate;
