import { createStyles } from '@mantine/core';

export const useStyles = createStyles( theme => ( {
	loaderComponent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '0.5rem',

		'& span > span': {
			background: theme.other.palette.gradients.primary,
		},
	},
} ) );
