/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import EntityRelationsFactory, { RelationshipConfig } from './relations/EntityRelationsFactory';
import RootStore from './RootStore';
import { RelationConstructor } from './relations/types';

type ArrayElement<ArrayType extends readonly unknown[]> =
		ArrayType extends readonly ( infer ElementType )[] ? ElementType : never;

export default abstract class StoreEntity<Relations extends unknown[] = []> {
	rootStore: RootStore | undefined;
	private __rootStore: RootStore | undefined;
	private __relationships: RelationConstructor[] | undefined;

	abstract update( other: unknown ): void

	static relationships(): RelationshipConfig[] { return []; }

	constructor( rootStore?: RootStore ) {
		this.createNonEnumerableProperty( '__rootStore', rootStore );
		this.createNonEnumerableProperty( '__relationships', [] );

		this.createRootStoreAttribute();
		this.createRelationshipsComputedAttributes();
	}

	getRelationship<T>( name: ArrayElement<Relations> ): T | null {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return this[ name ];
	}

	private createNonEnumerableProperty( propertyName: string, value: unknown ) {
		Object.defineProperty( this, propertyName, {
			enumerable: false,
			writable: true,
			value,
		} );
	}

	private createRootStoreAttribute() {
		Object.defineProperty( this, 'rootStore', {
			enumerable: false,
			get: () => this.__rootStore,
			set: ( rootStore ) => {
				this.__rootStore = rootStore;
				this.updateRelationshipsRootStore();
			},
		} );
	}

	private createRelationshipsComputedAttributes() {
		( this.constructor as typeof StoreEntity )
			.relationships()
			.forEach(
				relationship => this.createRelationshipComputedAttribute( relationship ),
			);
	}

	private createRelationshipComputedAttribute( relationship: RelationshipConfig ) {
		const relation = EntityRelationsFactory.relationFor( {
			model: this as Record<string, unknown>,
			rootStore: this.rootStore as RootStore,
			config: relationship,
		} );

		this.__relationships?.push( relation );

		Object.defineProperty( this, relationship.name, {
			enumerable: true,
			configurable: true,
			get: () => relation.value,
		} );
	}

	private updateRelationshipsRootStore() {
		this.__relationships?.forEach(
			( relationship ) => { relationship.rootStore = this.__rootStore as RootStore; },
		);
	}
}
