import { makeObservable, observable } from 'mobx';
import RootStore from '../stores/RootStore';
import Asset, { AssetConstructor, AssetJSON } from './Asset';

interface FontConstructor extends AssetConstructor {
	name: string;
}

interface FontJSON extends AssetJSON {
	name: string;
}

class Font extends Asset<[]> {
	name: string;

	constructor(
		{ name, ...assetFields }: FontConstructor,
		rootStore?: RootStore,
	) {
		super( assetFields, rootStore );
		this.name = name;

		makeObservable( this, {
			name: observable,
		} );
	}

	static fromJSON( json: Partial<FontJSON>, rootStore?: RootStore ) {
		if ( !this.isValid( json ) ) { throw new Error( 'Invalid JSON' ); }

		return new Font( {
			id: json.id,
			label: json.label,
			src: json.src,
			name: json.name,
		}, rootStore );
	}

	update( font: Omit<FontConstructor, 'id'> ): void {
		this.label = font.label;
		this.src = font.src;
		this.name = font.name;
	}

	toJSON(): FontJSON {
		return {
			id: this.id,
			label: this.label,
			src: this.src,
			name: this.name,
		};
	}

	private static isValid( json: Partial<FontJSON> ): json is FontJSON {
		return (
			json.id !== undefined
			&& json.label !== undefined
			&& json.src !== undefined
			&& json.name !== undefined
		);
	}
}

export default Font;
