import { createStyles } from '@mantine/core';
import { HEADER_HEIGHT } from '../../constants/sizes';

export const useStyles = createStyles( theme => ( {
	header: {
		backgroundColor: theme.other.palette.black,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: HEADER_HEIGHT,
		width: '100%',
		position: 'relative',
		padding: '1rem',
	},

	border: {
		borderBottom: '2px solid',
		borderImageSlice: 1,
		borderImageSource: theme.other.palette.gradients.primary,
	},

	rightSection: {
		position: 'absolute',
		right: '0.65625rem',
	},
} ) );
