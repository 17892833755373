import StoreEntity from '../stores/StoreEntity';
import RootStore from '../stores/RootStore';

type VideoStatus = 'pending' | 'started' | 'finished';

interface VideoConstructor {
	id: number;
	url: string;
	status: VideoStatus;
}

export interface VideoJSON {
	id: number;
	url: string;
	status: VideoStatus;
}

class Video extends StoreEntity {
	id: number;
	url: string;
	status: VideoStatus;

	constructor(
		{ id, url, status }: VideoConstructor,
		rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.url = url;
		this.status = status;
	}

	static fromJSON( json: Partial<VideoJSON> ): Video {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new this( {
			id: json.id,
			url: json.url,
			status: json.status,
		} );
	}

	toJSON(): VideoJSON {
		return ( {
			id: this.id,
			url: this.url,
			status: this.status,
		} );
	}

	update( { url, status }: {
			url: string;
			status: VideoStatus;
	} ) {
		this.url = url;
		this.status = status;
	}

	private static isValid( json: Partial<VideoJSON> ): json is VideoJSON {
		return json.id !== undefined
		&& json.url !== undefined
		&& json.status !== undefined;
	}
}

export default Video;
