import { MantineThemeOverride } from '@mantine/core';
import {
	XS_BREAKPOINT,
	SM_BREAKPOINT,
	MD_BREAKPOINT,
	LG_BREAKPOINT,
	XL_BREAKPOINT,
} from './constants/displayBrakepoints';
import { designSystem } from './designSystem';

const { palette, fonts } = designSystem;
const { headers, other } = fonts;

declare module '@mantine/core' {
  export interface MantineThemeOther {
		palette: typeof palette,
		fonts: typeof other,
  }
}

export const theme: MantineThemeOverride = {
	focusRing: 'never',
	fontFamily: 'League Spartan',
	headings: {
		fontFamily: headers.header1.fontFamily,
		fontWeight: headers.header1.fontWeight,
		sizes: {
			h1: { fontSize: headers.header1.fontSize, lineHeight: headers.header1.lineHeight },
			h2: { fontSize: headers.header2.fontSize, lineHeight: headers.header2.lineHeight },
			h3: { fontSize: headers.header3.fontSize, lineHeight: headers.header3.lineHeight },
		},
	},
	breakpoints: {
		xs: XS_BREAKPOINT,
		sm: SM_BREAKPOINT,
		md: MD_BREAKPOINT,
		lg: LG_BREAKPOINT,
		xl: XL_BREAKPOINT,
	},
	other: {
		palette: { ...palette },
		fonts: { ...other },
	},
};
