import { makeObservable } from 'mobx';
import EntityStore, { EntityStoreOptions } from './EntityStore';
import User from '../entities/User';
import RootStore from './RootStore';

export default class UserStore extends EntityStore<User> {
	private currentUserId?: number;

	constructor( rootStore: RootStore, options?: EntityStoreOptions ) {
		super( User, rootStore, options );
		makeObservable<UserStore, 'currentUserId'>( this, {
			currentUserId: true,
			currentUser: true,
			setCurrentUser: true,
		} );
	}

	get currentUser(): undefined | User {
		return this.currentUserId && this.get( this.currentUserId );
	}

	setCurrentUser( user: User ) {
		this.add( user );
		this.currentUserId = user.id;
	}

	clear() {
		super.clear();
		this.currentUserId = undefined;
	}
}
