import { makeObservable, observable } from 'mobx';
import { RelationshipConfig } from '../stores/relations/EntityRelationsFactory';
import Asset, { AssetConstructor, AssetJSON } from './Asset';
import AssetCategory from './AssetCategory';

interface ThemeConstructor extends AssetConstructor {
	thumbnailSrc: string;
	categoryIds: number[];
}

export interface ThemeTemplateJSON extends AssetJSON {
	thumbnail_src: string;
	theme_category_ids: number[];
}

class ThemeTemplate extends Asset<['categories']> {
	thumbnailSrc: string;
	categoryIds: number[];

	constructor( { thumbnailSrc, categoryIds, ...assetFields }: ThemeConstructor ) {
		super( assetFields );
		this.thumbnailSrc = thumbnailSrc;
		this.categoryIds = categoryIds;

		makeObservable( this, {
			thumbnailSrc: observable,
			categoryIds: observable,
		} );
	}

	static relationships(): RelationshipConfig[] {
		return [ {
			name: 'categories',
			type: 'HAS_MANY',
			store: 'themeCategoryStore',
			lookupKey: 'categoryIds',
		} ];
	}

	get categories() {
		return super.getRelationship<AssetCategory[]>( 'categories' );
	}

	static fromJSON( json: Partial<ThemeTemplateJSON> ) {
		if ( !this.isValid( json ) ) { throw new Error( 'Invalid JSON' ); }

		return new ThemeTemplate( {
			id: json.id,
			label: json.label,
			src: json.src,
			thumbnailSrc: json.thumbnail_src,
			categoryIds: json.theme_category_ids,
		} );
	}

	update( theme: Omit<ThemeConstructor, 'id'> ) {
		this.label = theme.label;
		this.src = theme.src;
		this.thumbnailSrc = theme.thumbnailSrc;
		this.categoryIds = theme.categoryIds;
	}

	toJSON(): ThemeTemplateJSON {
		return {
			id: this.id,
			label: this.label,
			src: this.src,
			thumbnail_src: this.thumbnailSrc,
			theme_category_ids: this.categoryIds,
		};
	}

	private static isValid( json: Partial<ThemeTemplateJSON> ): json is ThemeTemplateJSON {
		return json.id !== undefined
		&& json.label !== undefined
		&& json.src !== undefined
		&& json.thumbnail_src !== undefined
		&& json.theme_category_ids !== undefined;
	}
}

export default ThemeTemplate;
