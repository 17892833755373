import { CSSProperties, ReactNode } from 'react';
import { useStyles } from './styles';

interface CardProps {
		children: ReactNode | ReactNode[];
		className?: string;
		style?: CSSProperties;
}

const Card = ( {
	className = '',
	style,
	children,
}: CardProps ) => {
	const { classes, cx } = useStyles();

	return (
		<div className={cx( classes.card, className )} style={style}>
			<div className={classes.responsiveContainer}>
				{children}
			</div>
		</div>
	);
};

export default Card;
