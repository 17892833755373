/* eslint-disable no-use-before-define */
import AuthStore from './AuthStore';
import TokenStoreFactory from '../factories/TokenStoreFactory';
import EntityStore from './EntityStore';
import Video from '../entities/Video';
import GiftCard from '../entities/GiftCard';
import UserStore from './UserStore';
import ThemeTemplate from '../entities/ThemeTemplate';
import Slot from '../entities/Slot';
import AssetCategory from '../entities/AssetCategory';
import Font from '../entities/Font';
import StaticStickerTemplate from '../entities/StaticStickerTemplate';
import AnimatedStickerTemplate from '../entities/AnimatedStickerTemplate';
import GiftCardGroup from '../entities/GiftCardGroup';

type StoreEntry = [keyof RootStore, EntityStore<never>]
export type RootStoreSerializationType = Record<keyof RootStore, unknown[]>

type NonEntityStoreKeys = 'authStore' | 'cardAuthStore' | 'userStore' | 'hydrate' | 'clear' | 'toJSON'
export type EntityStoreKeysType = Exclude<keyof RootStore, NonEntityStoreKeys>;

export default class RootStore {
	cardAuthStore = TokenStoreFactory.forCard();
	slotAuthStore = TokenStoreFactory.forSlot();
	authStore = new AuthStore();
	userStore = new UserStore( this, { persists: false } );
	animatedStickerCategoryStore = new EntityStore( AssetCategory, this );
	staticStickerCategoryStore = new EntityStore( AssetCategory, this );
	themeCategoryStore = new EntityStore( AssetCategory, this );
	giftCardStore = new EntityStore( GiftCard, this, { persists: false } );
	giftCardGroupStore = new EntityStore( GiftCardGroup, this, { persists: false } );
	slotStore = new EntityStore( Slot, this, { persists: false } );
	videoStore = new EntityStore( Video, this, { persists: false } );
	fontStore = new EntityStore( Font, this, { persists: false } );
	animatedStickerStore = new EntityStore( AnimatedStickerTemplate, this, { persists: false } );
	staticStickerStore = new EntityStore( StaticStickerTemplate, this, { persists: false } );
	themeStore = new EntityStore( ThemeTemplate, this, { persists: false } );

	get persistedStores() {
		return this.storeEntries
			.filter( ( [ , store ] ) => store.persists );
	}

	hydrate( serialization: RootStoreSerializationType ) {
		Object.entries( serialization ).forEach( ( [ name, data ] ) => {
			const store = this[ name as keyof RootStore ] as EntityStore<never>;
			store.hydrate( data );
		} );
	}

	clear() {
		this.storeEntries.forEach( ( [ , store ] ) => store.clear && store.clear() );
	}

	toJSON() {
		return this.persistedStores.reduce( ( json, [ name, store ] ) => {
			// eslint-disable-next-line no-param-reassign
			json[ name ] = store.toJSON();
			return json;
		}, {} as RootStoreSerializationType );
	}

	private get storeEntries(): StoreEntry[] {
		return Object.entries( this ) as StoreEntry[];
	}
}

export type ExistingStore = Extract<keyof RootStore, `${string}Store`>
