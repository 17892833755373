import { createStyles } from '@mantine/core';

export const useStyles = createStyles( theme => ( {
	card: {
		borderRadius: '8px',
		backgroundColor: theme.other.palette.white,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		maxWidth: '41.25rem',
		padding: '1.5rem 1.25rem',
	},

	responsiveContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		maxWidth: '28.125rem',
	},
} ) );
