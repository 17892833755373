import { Text } from '@mantine/core';
import { useStyles } from './styles';

interface TitleAndSubtitleProps {
	title: string,
	subtitle: string,
	className?: string,
}

const TitleAndSubtitle = ( { title, subtitle, className }: TitleAndSubtitleProps ) => {
	const { classes, cx } = useStyles();
	return (
		<div className={cx( classes.titleAndSubtitleContainer, className )}>
			<Text className={classes.title}>{title}</Text>
			<Text className={classes.subtitle}>{subtitle}</Text>
		</div>
	);
};

export default TitleAndSubtitle;
