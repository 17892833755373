import React, { Suspense } from 'react';
import { MantineProvider } from '@mantine/core';
import { theme } from './theme';
import AppProvider from './providers/AppProvider';
import Navigation from './navigation/Navigation';
import 'react-toastify/dist/ReactToastify.css';
import ToastContainer from './components/ToastContainer/ToastContainer';
import { PersistGate } from './providers/RootStoreProvider';
import FullScreenLoader from './components/FullScreenLoader/FullScreenLoader';

const App = () => (
	<AppProvider>
		<PersistGate>
			<MantineProvider withNormalizeCSS withGlobalStyles theme={theme}>
				<Suspense fallback={<FullScreenLoader visible />}>
					<Navigation />
					<ToastContainer />
				</Suspense>
			</MantineProvider>
		</PersistGate>
	</AppProvider>
);
export default App;
