import { format, formatISO, parseISO } from 'date-fns';
import { makeObservable, observable } from 'mobx';
import StoreEntity from '../stores/StoreEntity';
import RootStore from '../stores/RootStore';
import { RelationshipConfig } from '../stores/relations/EntityRelationsFactory';
import Merchant, { MerchantJSON } from './Merchant';
import Slot, { SlotJSON } from './Slot';

interface GiftCardConstructor {
	id: number;
	slotId: number | null;
	scannedAt: Date | null;
	merchant: Merchant | null;
	slotStatus?: Slot['status']
}

export interface GiftCardJSON {
	id: number;
	slot_id: number | null;
	scanned_at: string | null;
	merchant: MerchantJSON | null;
	slot_status?: SlotJSON['status'];
}

export default class GiftCard extends StoreEntity<['slot']> {
	readonly id: number;
	slotId: number | null;
	scannedAt: Date | null;
	merchant: Merchant | null;
	slotStatus?: Slot['status'];

	constructor( {
		id,
		slotId,
		scannedAt,
		merchant,
		slotStatus,
	}: GiftCardConstructor,
	rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.slotId = slotId;
		this.scannedAt = scannedAt;
		this.merchant = merchant;
		this.slotStatus = slotStatus;

		makeObservable( this, {
			id: observable,
			slotId: observable,
			scannedAt: observable,
			merchant: observable,
		} );
	}

	static relationships(): RelationshipConfig[] {
		return [ {
			name: 'slot',
			lookupKey: 'slotId',
			store: 'slotStore',
			type: 'BELONGS_TO',
		} ];
	}

	get wasCompleted() {
		return this.slot ? this.slot?.wasCompleted : this.slotStatus === 'completed';
	}

	get wasActivated() {
		return this.wasCompleted || ( this.slot ? this.slot?.wasActivated : this.slotStatus === 'activated' );
	}

	get wasScanned() {
		return this.scannedAt !== null;
	}

	get formattedScannedAtDate() {
		return this.scannedAt
			? format( this.scannedAt, 'h:mm aaa, eee d MMM.' )
			: '';
	}

	get senderEmail() {
		return this.slot?.sender ? this.slot?.sender.email : null;
	}

	get from() {
		return this.slot?.from || null;
	}

	get to() {
		return this.slot?.to || null;
	}

	get videoUrl() {
		return this.slot?.videoUrl;
	}

	get merchantName() {
		return this.merchant?.name;
	}

	get slot() {
		return super.getRelationship<Slot>( 'slot' );
	}

	get currentBranding() {
		return this.merchant?.branding;
	}

	get defaultCtaLabel() {
		return this.merchant?.defaultCtaLabel;
	}

	toJSON(): GiftCardJSON {
		return {
			id: this.id,
			slot_id: this.slotId,
			scanned_at: this.formatDate( this.scannedAt ),
			merchant: this.merchant?.toJSON() || null,
			slot_status: this.slotStatus,
		};
	}

	static fromJSON( json: Partial<GiftCardJSON>, rootStore?: RootStore ): GiftCard {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new GiftCard( {
			id: json.id,
			slotId: json.slot_id,
			scannedAt: this.parseDate( json.scanned_at ),
			merchant: ( json.merchant && Merchant.fromJSON( json.merchant ) ) || null,
			slotStatus: json.slot_status,
		}, rootStore );
	}

	update( giftCard: Omit<GiftCardConstructor, 'id'> ) {
		this.slotId = giftCard.slotId;
		this.scannedAt = giftCard.scannedAt;
		this.merchant = giftCard.merchant;
	}

	private formatDate( date: Date | null ) {
		return date ? formatISO( date ) : null;
	}

	private static isValid( json: Partial<GiftCardJSON> ): json is GiftCardJSON {
		return true;
	}

	private static parseDate( dateJSON: string | null ) {
		return dateJSON ? parseISO( dateJSON ) : null;
	}
}
