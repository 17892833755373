import { makeObservable, observable } from 'mobx';
import { formatISO, parseISO, format } from 'date-fns';
import StoreEntity from '../stores/StoreEntity';
import RootStore from '../stores/RootStore';
import { RelationshipConfig } from '../stores/relations/EntityRelationsFactory';
import Video from './Video';
import User from './User';
import GiftCard from './GiftCard';
import GiftCardGroup from './GiftCardGroup';

type SlotStatus = 'activation started' | 'activated' | 'completed';

interface SlotConstructor {
	id: number;
	cardId: number | null;
	cardGroupId: number | null;
	videoId: number | null;
	senderId: number | null;
	status: SlotStatus;
	completedAt: Date | null;
	to: string | null;
	from: string | null;
	token: string | null;
}

export interface SlotJSON {
	id: number;
	card_id: number | null;
	card_group_id: number | null;
	processed_video_file_id: number | null;
	sender_id: number | null;
	status: SlotStatus;
	completed_at: string | null;
	to: string | null;
	from: string | null;
	sender_token: string | null;
}

export default class Slot extends StoreEntity<['card', 'cardGroup', 'video', 'sender']> {
	readonly id: number;
	cardId: number | null;
	cardGroupId: number | null;
	videoId: number | null;
	senderId: number | null;
	status: SlotStatus;
	completedAt: Date | null;
	to: string | null;
	from: string | null;
	token: string | null;

	constructor( {
		id,
		cardId,
		cardGroupId,
		videoId,
		senderId,
		status,
		completedAt,
		to,
		from,
		token,
	}: SlotConstructor,
	rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.cardId = cardId;
		this.cardGroupId = cardGroupId;
		this.videoId = videoId;
		this.senderId = senderId;
		this.status = status;
		this.completedAt = completedAt;
		this.to = to;
		this.from = from;
		this.token = token;

		makeObservable( this, {
			id: observable,
			cardId: observable,
			cardGroupId: observable,
			videoId: observable,
			senderId: observable,
			status: observable,
			completedAt: observable,
			to: observable,
			from: observable,
			token: observable,
		} );
	}

	static relationships(): RelationshipConfig[] {
		return [
			{
				name: 'card',
				lookupKey: 'cardId',
				store: 'giftCardStore',
				type: 'BELONGS_TO',
			}, {
				name: 'cardGroup',
				lookupKey: 'cardGroupId',
				store: 'giftCardGroupStore',
				type: 'BELONGS_TO',
			}, {
				name: 'video',
				lookupKey: 'videoId',
				store: 'videoStore',
				type: 'BELONGS_TO',
			}, {
				name: 'sender',
				lookupKey: 'senderId',
				store: 'userStore',
				type: 'BELONGS_TO',
			},
		];
	}

	static fromJSON( json: Partial<SlotJSON>, rootStore?: RootStore ): Slot {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );
		return new Slot( {
			id: json.id,
			cardId: json.card_id,
			cardGroupId: json.card_group_id,
			videoId: json.processed_video_file_id,
			senderId: json.sender_id,
			status: json.status,
			completedAt: this.parseDate( json.completed_at ),
			to: json.to,
			from: json.from,
			token: json.sender_token,
		}, rootStore );
	}

	get card() {
		return super.getRelationship<GiftCard>( 'card' );
	}

	get cardGroup() {
		return super.getRelationship<GiftCardGroup>( 'cardGroup' );
	}

	get video() {
		return super.getRelationship<Video>( 'video' );
	}

	get sender() {
		return super.getRelationship<User>( 'sender' );
	}

	get wasCompleted() {
		return this.status === 'completed';
	}

	get wasActivated() {
		return this.wasCompleted || this.status === 'activated';
	}

	get videoUrl() {
		return this.video?.url;
	}

	get formattedCompletedAtDate() {
		return this.completedAt
			? format( this.completedAt, 'eee d MMM.' )
			: '';
	}

	toJSON(): SlotJSON {
		return {
			id: this.id,
			card_id: this.cardId,
			card_group_id: this.cardGroupId,
			processed_video_file_id: this.videoId,
			sender_id: this.senderId,
			status: this.status,
			completed_at: this.formatDate( this.completedAt ),
			to: this.to,
			from: this.from,
			sender_token: this.token,
		};
	}

	update( slot: Omit<SlotConstructor, 'id'> ) {
		this.cardId = slot.cardId;
		this.videoId = slot.videoId;
		this.senderId = slot.senderId;
		this.status = slot.status;
		this.completedAt = slot.completedAt;
		this.to = slot.to;
		this.from = slot.from;
		this.token = slot.token;
	}

	private static isValid( json: Partial<SlotJSON> ): json is SlotJSON {
		return json.card_id !== undefined
		&& json.card_group_id !== undefined
		&& json.processed_video_file_id !== undefined
		&& json.sender_id !== undefined
		&& json.status !== undefined
		&& json.completed_at !== undefined
		&& json.sender_token !== undefined;
	}

	private formatDate( date: Date | null ) {
		return date ? formatISO( date ) : null;
	}

	private static parseDate( dateJSON: string | null ) {
		return dateJSON ? parseISO( dateJSON ) : null;
	}
}
