import RootStore from '../stores/RootStore';
import StoreEntity from '../stores/StoreEntity';

interface UserConstructor {
		id: number;
		email: string;
}

export interface UserJSON {
		id: number;
		email: string;
}

class User extends StoreEntity {
	id: number;
	email: string;

	constructor( {
		id,
		email,
	}: UserConstructor,
	rootStore?: RootStore ) {
		super( rootStore );
		this.id = id;
		this.email = email;
	}

	static fromJSON( json: Partial<UserJSON>, rootStore?: RootStore ): User {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new this( {
			id: json.id,
			email: json.email,
		}, rootStore );
	}

	toJSON(): UserJSON {
		return ( {
			id: this.id,
			email: this.email,
		} );
	}

	update( { email }: Omit<UserConstructor, 'id'> ) {
		this.email = email;
	}

	private static isValid( json: Partial<UserJSON> ): json is UserJSON {
		return json.email !== undefined;
	}
}

export default User;
