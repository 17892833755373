import React from 'react';
import { toast } from 'react-toastify';

interface ToastConfig {
	title?: string,
	description: string,
}

type ToastId = string | number;

class ToastService {
	showError( config: ToastConfig ): ToastId {
		return toast.error( this.generateToastContent( config ) );
	}

	showInfo( config: ToastConfig ): ToastId {
		return toast.info( this.generateToastContent( config ) );
	}

	showSuccess( config: ToastConfig ): ToastId {
		return toast.success( this.generateToastContent( config ) );
	}

	private generateToastContent( { title, description }: ToastConfig ) {
		return (
			<>
				{title && <p>{title}</p>}
				<p>{description}</p>
			</>
		);
	}
}

export default ToastService;
