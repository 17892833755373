import { createStyles } from '@mantine/core';

export const useStyles = createStyles( theme => ( {
	renderingText: {
		...theme.other.fonts.captionRegular,
		color: theme.other.palette.white,
		textAlign: 'center',
	},
	doNotCloseText: {
		...theme.other.fonts.captionBold,
		color: theme.other.palette.white,
	},
} ) );
