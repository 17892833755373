import { createStyles } from '@mantine/core';

export const useStyles = createStyles( theme => ( {
	toastInfo: {
		'--toastify-color-info': `${theme.other.palette.info.default} !important`,
		'--toastify-icon-color-info': `${theme.other.palette.info.default} !important`,
		'--toastify-color-progress-info': `${theme.other.palette.info.default} !important`,
	},
	toastSuccess: {
		'--toastify-color-success': `${theme.other.palette.success.default} !important`,
		'--toastify-icon-color-success': `${theme.other.palette.success.default} !important`,
		'--toastify-color-progress-success': `${theme.other.palette.success.default} !important`,
	},
	toastWarning: {
		'--toastify-color-warning': `${theme.other.palette.warning.default} !important`,
		'--toastify-icon-color-warning': `${theme.other.palette.warning.default} !important`,
		'--toastify-color-progress-warning': `${theme.other.palette.warning.default} !important`,
	},
	toastError: {
		'--toastify-color-error': `${theme.other.palette.error.default} !important`,
		'--toastify-icon-color-error': `${theme.other.palette.error.default} !important`,
		'--toastify-color-progress-error': `${theme.other.palette.error.default} !important`,
	},
} ) );
