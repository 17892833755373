import { reaction } from 'mobx';
import StorageService from '../services/StorageService';
import RootStore, { RootStoreSerializationType } from './RootStore';

export const STORAGE_KEY = '__ROOT_STORE_1.9.0__';
export const PERSIST_DELAY = 1000;

export default class RootStorePersistor {
	rootStore: RootStore;
	storage: StorageService;

	constructor(
		rootStore: RootStore,
		storage = StorageService.localStorage(),
	) {
		this.rootStore = rootStore;
		this.storage = storage;

		reaction(
			() => this.rootStore.toJSON(),
			() => this.persist(),
			{
				delay: PERSIST_DELAY,
			},
		);
	}

	persist() {
		const serialization = JSON.stringify( this.rootStore.toJSON() );
		this.storage.setItem( STORAGE_KEY, serialization, { expirationInDays: 1 } );
	}

	hydrate() {
		const serialization = JSON.parse( this.storage.getItem( STORAGE_KEY ) || '{}' );
		try {
			this.rootStore.hydrate( serialization as RootStoreSerializationType );
		} catch {
			this.storage.removeItem( STORAGE_KEY );
		}
	}
}
