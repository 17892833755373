import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NoMatch from '../screens/NoMatch/NoMatch';
import { RouteProps, routes } from './routes';
import RequireAuth from './components/RequireAuth';

const Navigation: React.FC = () => {
	const getRouteProps = ( route: RouteProps ) => ( {
		path: route.path,
		element: route.requireAuth
			? (
				<RequireAuth key={route.path}>
					{route.element}
				</RequireAuth>
			)
			: route.element,
		key: route.path,
	} );

	const addPublicRoutes = () => (
		routes.map( route => <Route {...getRouteProps( route )} /> )
	);

	return (
		<BrowserRouter>
			<Routes>
				{addPublicRoutes()}
				<Route path="*" element={<NoMatch />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Navigation;
