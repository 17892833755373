import { makeAutoObservable } from 'mobx';
import StorageService from '../services/StorageService';
import AccessToken from '../structures/AccessToken';

const TOKEN_KEY = 'token';

export default class AuthStore {
	storageService: StorageService;
	accessToken: AccessToken | null;

	constructor( storageService: StorageService = StorageService.localStorage() ) {
		this.accessToken = null;
		this.storageService = storageService;
		makeAutoObservable( this );

		this.checkForTokenInStorage();
	}

	get isAuthenticated() {
		return !!this.accessToken;
	}

	get token() {
		return this.accessToken?.token || null;
	}

	get refreshToken() {
		return this.accessToken?.refreshToken;
	}

	setAccessToken( token: AccessToken | null ) {
		this.setTokenInStorage( token );
		this.accessToken = token;
	}

	tokenIsValid() {
		return !!this.accessToken?.isValid();
	}

	clear() {
		this.setAccessToken( null );
	}

	private checkForTokenInStorage() {
		const accessTokenJSON = this.storageService.getItem( TOKEN_KEY );
		if ( accessTokenJSON ) {
			const json = JSON.parse( accessTokenJSON );
			this.accessToken = AccessToken.fromJSON( json );
		}
	}

	private setTokenInStorage( token: AccessToken | null ) {
		if ( token ) {
			const json = JSON.stringify( token.toJSON() );
			this.storageService.setItem( TOKEN_KEY, json );
		} else {
			this.storageService.removeItem( TOKEN_KEY );
		}
	}
}
