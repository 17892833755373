import TokenStore from '../stores/TokenStore';

export default class TokenStoreFactory {
	static forCard() {
		return new TokenStore( 'card-token' );
	}

	static forSlot() {
		return new TokenStore( 'slot-token' );
	}
}
