interface BrandingConstructor {
	id: number;
	url: string | null;
	logoSrc: string | null;
	tagLine: string | null;
	ctaLabel: string | null;
	ctaLink: string | null;
}

export interface BrandingJSON {
	id: number;
	website_url: string | null;
	logo_src: string | null;
	tag_line: string | null;
	call_to_action_label: string | null;
	call_to_action_link: string | null;
}

class Branding {
	id: number;
	url: string | null;
	logoSrc: string | null;
	tagLine: string | null;
	ctaLabel: string | null;
	ctaLink: string | null;

	constructor( {
		id, url, logoSrc, tagLine, ctaLabel, ctaLink,
	}: BrandingConstructor ) {
		this.id = id;
		this.url = url;
		this.logoSrc = logoSrc;
		this.tagLine = tagLine;
		this.ctaLabel = ctaLabel;
		this.ctaLink = ctaLink;
	}

	get hasWebsite() {
		return this.url !== null;
	}

	get hasLogo() {
		return this.logoSrc !== null;
	}

	get hasCallToAction() {
		return !!this.ctaLink || !!this.url;
	}

	get logoUrl() {
		return this.url || undefined;
	}

	get customLogo() {
		return this.logoSrc || undefined;
	}

	getTagLine() {
		return this.tagLine || 'Spread the love!';
	}

	getCtaLink() {
		return this.ctaLink || this.url;
	}

	toJSON(): BrandingJSON {
		return {
			id: this.id,
			website_url: this.url,
			logo_src: this.logoSrc,
			tag_line: this.tagLine,
			call_to_action_label: this.ctaLabel,
			call_to_action_link: this.ctaLink,
		};
	}

	static fromJSON( json: Partial<BrandingJSON> ) {
		if ( !this.isValid( json ) ) throw Error( 'Invalid JSON' );

		return new this( {
			id: json.id,
			url: json.website_url ? json.website_url : null,
			logoSrc: json.logo_src ? json.logo_src : null,
			tagLine: json.tag_line ? json.tag_line : null,
			ctaLabel: json.call_to_action_label ? json.call_to_action_label : null,
			ctaLink: json.call_to_action_link ? json.call_to_action_link : null,
		} );
	}

	private static isValid( json: Partial<BrandingJSON> ): json is BrandingJSON {
		return json.id !== undefined
    && json.website_url !== undefined
    && json.logo_src !== undefined
    && json.tag_line !== undefined
    && json.call_to_action_label !== undefined
    && json.call_to_action_link !== undefined;
	}
}

export default Branding;
