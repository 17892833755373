import { RelationConstructor, StoreNameType } from './types';
import RootStore from '../RootStore';
import EntityStore from '../EntityStore';

export default class BelongsToRelation {
	model: Record<string, unknown>;
	rootStore: RootStore;
	storeName: StoreNameType;
	lkName: string;

	constructor( {
		model, rootStore, storeName, lkName,
	}: RelationConstructor ) {
		this.model = model;
		this.rootStore = rootStore;
		this.storeName = storeName;
		this.lkName = lkName;
	}

	get relatedId() {
		return this.model[ this.lkName ] as number;
	}

	get store(): EntityStore<never> | null {
		if ( !this.rootStore ) return null;
		const store = this.rootStore[ this.storeName ] as unknown as EntityStore<never>;
		return this.rootStore ? store : null;
	}

	get exists() {
		return this.store?.has( this.relatedId );
	}

	get value() {
		return this.store?.get( this.relatedId ) || null;
	}
}
