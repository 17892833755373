import { makeObservable, observable } from 'mobx';
import RootStore from '../stores/RootStore';
import StoreEntity from '../stores/StoreEntity';

interface AssetCategoryConstructor {
	id: number;
	name: string;
}

export interface AssetCategoryJSON {
	id: number;
	name: string;
}

class AssetCategory extends StoreEntity {
	id: number;
	name: string;

	constructor(
		{ id, name }: AssetCategoryConstructor,
		rootStore?: RootStore,
	) {
		super( rootStore );
		this.id = id;
		this.name = name;

		makeObservable( this, {
			id: observable,
			name: observable,
		} );
	}

	update( category: Omit<AssetCategoryConstructor, 'id'> ) {
		this.name = category.name;
	}

	toJSON(): AssetCategoryJSON {
		return {
			id: this.id,
			name: this.name,
		};
	}

	static fromJSON( json: Partial<AssetCategoryJSON>, rootStore?: RootStore ) {
		if ( !this.isValid( json ) ) {
			throw new Error( 'Invalid JSON' );
		}

		return new this(
			{ id: json.id, name: json.name },
			rootStore,
		);
	}

	private static isValid( json: Partial<AssetCategoryJSON> ): json is AssetCategoryJSON {
		return (
			json.id !== undefined
			&& json.name !== undefined
		);
	}
}

export default AssetCategory;
