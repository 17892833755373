import StickerTemplate, { StickerTemplateJSON } from './StickerTemplate';

class AnimatedStickerTemplate extends StickerTemplate {
	static fromJSON( json: Partial<StickerTemplateJSON> ) {
		if ( !this.isValid( json ) ) { throw new Error( 'Invalid JSON' ); }
		return new this( {
			id: json.id,
			label: json.label,
			src: json.src,
			thumbnailSrc: json.thumbnail_src,
			categoryIds: json.sticker_category_ids,
		} );
	}
}

export default AnimatedStickerTemplate;
