import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { hotjar } from 'react-hotjar';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { configure } from '@amalgamaco/mobx-form';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ampli } from './ampli';
import { INVALID_EMAIL, INVALID_SHORT_PASSWORD, REQUIRED_FIELD } from './constants/fieldErrors';

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement,
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ampli.load( { client: { apiKey: process.env.REACT_APP_AMPLITUDE_KEY as string } } );

reportWebVitals();

// Initialize analytics
if ( process.env.NODE_ENV === 'production' ) {
	hotjar.initialize(
				process.env.REACT_APP_HOTJAR_ID as unknown as number,
				process.env.REACT_APP_HOTJAR_VERSION as unknown as number,
	);
}

// Initialize Firebase
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp( firebaseConfig );
getAuth( app );

configure( {
	validators: {
		email: { message: INVALID_EMAIL },
		minLength: { message: INVALID_SHORT_PASSWORD },
		required: { message: REQUIRED_FIELD },
	},
} );
