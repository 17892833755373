import {
	createContext, ReactNode, useContext, useMemo,
} from 'react';
import UIService from '../ui-services/UIService';

const UIServiceContext = createContext( {} as UIService );

const UIServiceProvider = ( { children }: { children: ReactNode } ) => {
	const UiService: UIService = useMemo( () => new UIService(), [] );
	return (
		<UIServiceContext.Provider value={UiService}>
			{children}
		</UIServiceContext.Provider>
	);
};

export const useUIService = () => useContext( UIServiceContext );

export default UIServiceProvider;
