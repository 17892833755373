import { BarLoader } from 'react-spinners';
import { CSSProperties } from 'react';
import { ReactComponent as SwaqIcon }
	from '../../../assets/icons/swaq-icon-alt.svg';
import { designSystem } from '../../../designSystem';
import { useStyles } from '../styles';

const override: CSSProperties = {
	border: `1px solid ${designSystem.palette.white}`,
	borderRadius: '100px',
};

const LoaderComponent = () => {
	const { classes } = useStyles();
	return (
		<div className={classes.loaderComponent}>
			<SwaqIcon />
			<BarLoader color="white" loading cssOverride={override} />
		</div>
	);
};

export default LoaderComponent;
