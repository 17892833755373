import React, { lazy } from 'react';
import NoMatch from '../screens/NoMatch/NoMatch';

const Landing = lazy( () => import( '../screens/Landing/Landing' ) );
const UploadVideo = lazy( () => import( '../screens/UploadVideo/UploadVideo' ) );
const CardActivation = lazy( () => import( '../screens/CardActivation/MerchantCardActivation' ) );
const WatchVideo = lazy( () => import( '../screens/WatchVideo/WatchVideo' ) );
const DeleteVideo = lazy( () => import( '../screens/DeleteVideo/DeleteVideo' ) );
const CreateSenderAccount = lazy( () => import( '../screens/CreateAccount/CreateSenderAccount' ) );
const CreateRecipientAccount = lazy( () => import( '../screens/CreateAccount/CreateRecipientAccount' ) );
const RecoverPassword = lazy( () => import( '../screens/RecoverPassword/RecoverPassword' ) );
const ResetPassword = lazy( () => import( '../screens/ResetPassword/ResetPassword' ) );
const Library = lazy( () => import( '../screens/Library/Library' ) );

export const LANDING_PATH = '/';
export const ERROR_PATH = '/error';
export const UPLOAD_VIDEO_PATH = '/upload-video';
export const DELETE_VIDEO_PATH = '/delete-video';
export const WATCH_VIDEO_PATH = '/watch';
export const CARD_ACTIVATION_PATH = '/activate';
export const CREATE_SENDER_ACCOUNT_PATH = '/create-account-sender';
export const CREATE_RECIPIENT_ACCOUNT_PATH = '/create-account-recipient';
export const LOGIN_AS_SENDER_PATH = '/login-sender';
export const LOGIN_AS_RECIPIENT_PATH = '/login-recipient';
export const LIBRARY_PATH = '/library';
export const RECOVER_PASSWORD_PATH = '/recover-password';
export const RESET_PASSWORD_PATH = '/reset-password';

export interface RouteProps {
	path: string,
	element: JSX.Element,
	requireAuth?: boolean,
}

const authRoutes: RouteProps[] = [
	{
		path: CREATE_SENDER_ACCOUNT_PATH,
		element: <CreateSenderAccount />,
	}, {
		path: CREATE_RECIPIENT_ACCOUNT_PATH,
		element: <CreateRecipientAccount />,
	}, {
		path: LOGIN_AS_SENDER_PATH,
		element: <CreateSenderAccount />,
	}, {
		path: LOGIN_AS_RECIPIENT_PATH,
		element: <CreateRecipientAccount />,
	},
];

export const routes: RouteProps[] = [
	{
		path: UPLOAD_VIDEO_PATH,
		element: <UploadVideo />,
	},
	{
		path: CARD_ACTIVATION_PATH,
		element: <CardActivation />,
	},
	{
		path: LANDING_PATH,
		element: <Landing />,
	}, {
		path: WATCH_VIDEO_PATH,
		element: <WatchVideo />,
	}, {
		path: DELETE_VIDEO_PATH,
		element: <DeleteVideo />,
	}, {
		path: RECOVER_PASSWORD_PATH,
		element: <RecoverPassword />,
	}, {
		path: RESET_PASSWORD_PATH,
		element: <ResetPassword />,
	},
	...authRoutes,
	{
		path: ERROR_PATH,
		element: <NoMatch />,
	}, {
		path: LIBRARY_PATH,
		element: <Library />,
		requireAuth: true,
	},
];
